import React from 'react';
import InsightSection from '../components/Medicals/InsightSection';
import FeaturesCards from '../components/Medicals/FeaturesCards';

import "../components/Medicals/style/Medicals.css"


const Medicals = () => {

    
    return (
        <div className="data-mastery-container">
            
            <div className="header-section">
                <span className="badge">IT - service</span>
                <h1>Elettromedicali</h1>
                <p>
                    With our advanced tools, achieving data mastery has never been easier,
                    allowing you to fully understand and leverage your data for optimal decision-making.
                </p>
            </div>

            {/* Sezione Insights */}
            <InsightSection />

            {/* Sezione Feature Cards */}
            <FeaturesCards />
        </div>
    );
};

export default Medicals;
