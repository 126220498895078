import React from "react";
import { Button } from "primereact/button";
import "../style/Footer.css";
import LogoImg from "../asset/Logo.png";

const Footer = () => {
  // Funzione per scorrere in alto
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Funzione per aprire il link LinkedIn
  const openLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/company/se-me-te-srl/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        {/* Logo e descrizione */}
        <div className="footer-logo">
          <img src={LogoImg} alt="Company Logo" />
          <p>Via Benedetto Croce, 8, 95039, Trecastagni (CT)</p>
          <p>P.IVA: 05862030870</p>
        </div>

        <div className="footer-links">
          <div className="footer-left">
            <div className="footer-section">
              <h4 className="title">Chi siamo</h4>
              <p>Azienda</p>
              <p>Certificazioni</p>
              <p>Informazioni</p>
            </div>
          </div>

          {/* Sezione Destra */}
          <div className="footer-right">
            <div className="footer-section">
              <h4 className="title">Contatti</h4>
              <p>E-mail: devsemete@gmail.com</p>
              <p>Telefono: +39 349 149 8790</p>
            </div>
            <div className="footer-section">
              <h4 className="title">Orari</h4>
              <p>Lunedì - Venerdì: 9:00 - 18:00</p>
              <p>Sabato - Domenica: Chiuso</p>
            </div>
          </div>
        </div>

        {/* Sezione Social */}
        <div className="footer-social">
          <p className="title_social">Seguici Su</p>
          <a href="https://www.linkedin.com/company/se-me-te-srl/">
            <i className="pi pi-linkedin"></i>
          </a>
        </div>
      </div>

      {/* Parte inferiore del footer */}
      <div className="footer-bottom">
        <p>
          &copy; 2024 Se.Me.Te SRL Tutti i diritti riservati. |{" "}
          <a href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>

      {/* Bottone per tornare in alto */}
      <Button
        icon="pi pi-arrow-up"
        className="scroll-top-btn"
        onClick={scrollToTop}
        aria-label="Torna su"
      />
    </footer>
  );
};

export default Footer;
