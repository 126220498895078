import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Medicals from "./pages/Medicals";
import Informatics from "./pages/Informatics";
import SupportPage from "./components/Support/SupportPage";
// import ParticlesBk from './components/Particles/ParticlesBk';

import "./App.css";
import PrivacyPolicyPage from "./pages/Privacy-Policy";
import PrivacyPolicyRicontattoPage from "./pages/Privacy-Policy-Ricontatto";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        {/* <ParticlesBk/> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/medich" element={<Medicals />} />
          <Route path="/tech" element={<Informatics />} />
          <Route path="/supporto" element={<SupportPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/privacy-policy-form-ricontatto"
            element={<PrivacyPolicyRicontattoPage />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
