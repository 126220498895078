import React from 'react';
import '../../style/CompanyDescription.css'; 
import logo from '../../asset/Logo.png';

const CompanyDescription = () => {
  return (
    <section className="company-description">
      <div className="description-container">
        <div className="description-text">
          <h2 className='title_about'>Il tuo partner per soluzioni su misura</h2>
          <p>
          Nata nel 2021, Se.Me.Te è una SRL specializzata nel settore informatico. Offriamo una vasta gamma di servizi, tra cui programmazione, vendita e noleggio di dispositivi come computer, sistemi integrati e smartphone, oltre a un'assistenza tecnica personalizzata per soddisfare ogni esigenza.
          </p>
          <p>
          Ci rivolgiamo sia a clienti privati che pubblici, offrendo soluzioni su misura per chiunque voglia acquistare un nuovo dispositivo, riparare il proprio o realizzare un sistema IT progettato per le proprie necessità. Con noi, competenza e attenzione sono sempre al primo posto.
          </p>
        </div>
        <div className="description-image">
          <img src={logo} alt="Immagine aziendale" />
        </div>
      </div>
    </section>
  );
};

export default CompanyDescription;
