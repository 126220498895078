import React, { useState } from "react";

const FormInfo = () => {
  const [step, setStep] = useState(1); // Gestisce lo stato della pagina
  const [progress, setProgress] = useState(25); // Percentuale della barra di progresso
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    indirizzo: "",
    cellulare: "",
    problema: "",
    opzione: "",
  });

  // Avanza di una pagina o invia il form
  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
      setProgress(progress + 50);
    } else {
      handleSubmit();
    }
  };

  // Torna indietro di una pagina
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      setProgress(progress - 50);
    }
  };

  // Gestione input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Funzione per inviare il form
  const handleSubmit = async () => {
    try {
      console.log("Invio questi dati");
      console.log(JSON.stringify(formData));
      const response = await fetch(
        "https://www.semete.it/servizi/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const result = await response.text();
        alert(result);
      } else {
        alert("Errore durante l'invio del messaggio.");
      }
    } catch (error) {
      console.error("Errore durante la richiesta:", error);
      alert("Errore durante la connessione al server.");
    }
  };

  return (
    <div className="multi-step-form">
      <h2>Contattaci</h2>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <form>
        {step === 1 && (
          <div className="step step-1">
            <div className="form-field">
              <label htmlFor="nome">Nome</label>
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                placeholder="nome"
              />
            </div>
            <div className="form-field">
              <label htmlFor="indirizzo">E-mail</label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="la tua e-mail"
              />
            </div>
            <div className="form-field">
              <label htmlFor="indirizzo">Indirizzo</label>
              <input
                type="text"
                id="indirizzo"
                name="indirizzo"
                value={formData.indirizzo}
                onChange={handleChange}
                placeholder="Il tuo indirizzo"
              />
            </div>
            <div className="form-field">
              <label htmlFor="cellulare">Cellulare</label>
              <input
                type="text"
                id="cellulare"
                name="cellulare"
                value={formData.cellulare}
                onChange={handleChange}
                placeholder="Il tuo numero di cellulare"
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="step step-2">
            <div className="form-field">
              <label htmlFor="opzione">Seleziona un'opzione</label>
              <select
                id="opzione"
                name="opzione"
                value={formData.opzione}
                onChange={handleChange}
              >
                <option value="">Seleziona...</option>
                <option value="supporto">Supporto tecnico</option>
                <option value="informazioni">Richiesta informazioni</option>
                <option value="altro">Altro</option>
              </select>
            </div>
            {formData.opzione && (
              <div className="form-field">
                <label htmlFor="problema">Descrivi il tuo problema</label>
                <textarea
                  id="problema"
                  name="problema"
                  rows="4"
                  value={formData.problema}
                  onChange={handleChange}
                  placeholder="Descrivi qui il tuo problema"
                ></textarea>
              </div>
            )}
          </div>
        )}

        <div className="button-container">
          {step > 1 && (
            <button type="button" onClick={handleBack} className="back-button">
              Back
            </button>
          )}
          <button type="button" onClick={handleNext} className="next-button">
            {step < 2 ? "Next" : "Invia"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormInfo;
