import React, { useState, useEffect } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../style pages/Home.css";

import Usefulness from "../components/Home/Usefulness";
import Slideshow from "../components/Home/SlideShow";
import Presentation from "../components/Home/Presentation";
// import TechNews from '../components/Home/TechNews';

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  //   useEffect(() => {
  //     setShowPopup(true);
  //   }, []);

  const handleAccept = () => {
    setShowPopup(false); // Nascondi il popup
  };

  const handleReject = () => {
    window.location.href = "https://www.google.com"; // Reindirizza a una pagina esterna
  };

  return (
    <>
      {showPopup && (
        <>
          {/* <div className="overlay"></div> */}
          <div className="cookie-popup">
            <p>Questo sito non utilizza cookie.</p>
            <button className="accept" onClick={handleAccept}>
              Ok
            </button>
            {/* <button className="reject" onClick={handleReject}>
              Rifiuta
            </button> */}
          </div>
        </>
      )}

      <div className="home_container">
        <Slideshow />
        <Presentation />
        <Usefulness />
        {/* <TechNews /> */}
      </div>
    </>
  );
};

export default Home;
