import React, { useState, useEffect } from "react";
import { Carousel } from 'primereact/carousel';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';           
import 'primeicons/primeicons.css';                        
import './style/SlideShow.css';           


import ItSistem from "../../asset/ItSistem.jpg";
import Coding from "../../asset/Coding.jpg";
import Riparation from "../../asset/Riparation.jpg";
import Solution from "../../asset/Solution.jpg";


const images = [ItSistem, Coding, Riparation, Solution];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (step) => {
    setCurrentSlide((prev) => (prev + step + images.length) % images.length);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      changeSlide(1);
    }, 5000);

    return () => clearInterval(timer); 
  }, []);

  return (
    <div className="slideshow-container">
      {/* Slides */}
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? "active" : ""}`}>
          
          <img src={image} alt={`Slide ${index}`} className="carousel-image" />
        </div>
      ))}

      {/* Controlli */}
      <button className="prev" onClick={() => changeSlide(-1)}>
        &#10094;
      </button>
      <button className="next" onClick={() => changeSlide(1)}>
        &#10095;
      </button>

    </div>
  );
};

export default Slideshow;

