import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import LogoImg from "../asset/Logo.png";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "../style/Navbar.css";

const Navbar = () => {

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="nav">
      <div className="nav_start">
        <Link to="/" className="nav_link">
            <img alt="logo" src={LogoImg} height="80" className="navbar_logo" />
        </Link>
      </div>

      {/* Menu hamburger per dispositivi mobili */}
      <button className="hamburger_menu" onClick={toggleMobileMenu}>
        <i className="pi pi-bars"></i>
      </button>

      {/* Schermo del menu mobile che si apre su clic */}
      {isMobileMenuOpen && (
        <div className="mobile_menu_overlay">
          <button className="close_menu_button" onClick={toggleMobileMenu}>
            <i className="pi pi-times"></i>
          </button>

          <div className="mobile_menu_content">
            <img alt="logo" src={LogoImg} height="80" className="mobile_logo" />
            <ul className="mobile_nav_menu">
              <li className="mobile_nav_item">
                <Link to="/" className="nav_link" onClick={toggleMobileMenu}>
                  <i className="pi pi-home"></i> Home
                </Link>
              </li>
              <li className="mobile_nav_item">
                <Link to="/servizi" className="nav_link" onClick={toggleMobileMenu}>
                  <i className="pi pi-cog"></i> Servizi
                </Link>
              </li>
              <li className="mobile_nav_item">
                <Link to="/about" className="nav_link" onClick={toggleMobileMenu}>
                  <i className="pi pi-briefcase"></i> Azienda
                </Link>
              </li>
              <li className="mobile_nav_item">
                <Link to="/supporto" className="nav_link" onClick={toggleMobileMenu}>
                  <i className="pi pi-question-circle"></i> Supporto
                </Link>
              </li>
            </ul>
            <Button label="Hai bisogno d'aiuto?" icon="pi pi-envelope" className="p-button-rounded p-button-help navbar-button" />
          </div>
        </div>
      )}

      <ul className="nav_menu">
        <li className="nav_item">
          <Link to="/" className="nav_link">
            <i className="pi pi-home"></i> Home
          </Link>
        </li>

        {/* <li className="nav_item" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <span className="nav_link">
            <i className="pi pi-cog"></i> Servizi
          </span>

          {dropdownVisible && (
            <ul className="dropdown_menu">
              <li>
                <Link to="/medich" className="nav_link">
                  <i className="pi pi-tags"></i> Elettromedicali
                </Link>
              </li>
              <li>
                <Link to="/tech" className="nav_link">
                  <i className="pi pi-question-circle"></i> Informatica
                </Link>
              </li>
            </ul>
          )}
        </li> */}

        <li className="nav_item">
          <Link to="/about" className="nav_link">
            <i className="pi pi-briefcase"></i> Azienda
          </Link>
        </li>

        <li className="nav_item">
          <Link to="/supporto" className="nav_link">
            <i className="pi pi-question-circle"></i> Supporto
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
