import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import FormInfo from "../FormInfo";
import "./style/Presentation.css";


import iconSup from "../../asset/iconSup.svg";
import iconAs from "../../asset/iconAs.svg";
import iconIT from "../../asset/iconIT.svg";
import iconSite from "../../asset/iconSite.svg";
import iconApp from "../../asset/iconApp.svg";

// import iconTav from "../../asset/iconTav.svg";
// import iconPoli from "../../asset/iconPoli.svg";



const Presentation = () => {
    const [category, setCategory] = useState("IT"); // Stato per la categoria
    const [displayContact, setDisplayContact] = useState(false);
    const [animating, setAnimating] = useState(false); // Stato per l'animazione


    const categories = {
        IT: {
            title: "Benvenuto su Se.Me.Te",
            description: "Offriamo servizi nel settore informatico per rispondere alle tue necessità con cura e competenza. Scopri cosa possiamo fare per te.",
            descriptionColor: "#ffffff",
            services: [
                { title: "Supporto", description: "Supporto rapido da remoto, per acquisto dispositivi e software o risoluzione dei problemi. Se necessario, fissiamo un appuntamento in loco.", icon: iconSup },
                { title: "Assistenza Tecnica", description: "Ci occupiamo di installazione, configurazione, riparazione e manutenzione del tuo dispositivo.", icon: iconAs },
                { title: "IT Solution", description: "Con il nostro servizio di sistemistica, configuriamo e ottimizziamo reti, server e sistemi IT per garantire prestazioni sicure e affidabili, sia per privati che aziende.", icon: iconIT },
                { title: "Siti Web", description: "realizziamo siti web su misura, tra cui e-commerce, gestionali, CRM e CMS", icon: iconSite },
                { title: "App Dev", description: "Applicazioni iOS e Android, per ottimizzare l’accesso del tuo sito anche da smartphone", icon: iconApp },
            ],
            colorClass: "blue-theme", // Classe CSS per il tema IT
        },
        // Elettromedicale: {
        //     title: "Elettromedicale",
        //     description: "Offriamo servizi nei settori informatico ed elettromedicale, per rispondere alle tue necessità con cura e competenza. Scopri cosa possiamo fare per te.",
        //     descriptionColor: "#ffffff",
        //     services: [
        //         { title: "Supporto da remoto", description: "Per risolvere problemi sui tuoi dispositivi elettromedicali in modo rapido ed efficace.", icon: iconSup },
        //         { title: "Assistenza Domiciliare", description: "Il nostro staff offre manutenzione, configurazione e installazione dei dispositivi elettromedicali direttamente a casa tua", icon: iconTav },
        //         { title: "Noleggio Poligrafi", description: "Offriamo la possibilità di noleggiare poligrafi e altri dispositivi elettromedicali grazie alla collaborazione di aziende terze.", icon: iconPoli },
        //     ],
        //     colorClass: "green-theme", 
        // },
    };

    const toggleCategory = () => {
        setAnimating(true);
        setTimeout(() => {
            setCategory((prevCategory) => (prevCategory === "IT" ? "Elettromedicale" : "IT"));
            setAnimating(false);
        }, 500);
    };

    const currentCategory = categories[category];

    const openContactForm = () => setDisplayContact(true);
    const closeContactForm = () => setDisplayContact(false);


    return (
        <div className={`presentation-container ${currentCategory.colorClass} ${animating ? "animating" : ""}`}>
            <div className="top_descrbtn">
                <h2 className="presentation-title">{currentCategory.title}</h2>
                {/* <Button
          label={`Passa a ${category === "IT" ? "Elettromedicale" : "IT"}`}
          className="switch_button p-button-outlined"
          onClick={toggleCategory}
        /> */}
            </div>
            <div className="top_descrbtn">
                <p className="presentation-description" style={{ color: currentCategory.descriptionColor }}>{currentCategory.description}</p>
            </div>

            <div className={`card-container ${animating ? "animating" : ""}`}>
                {currentCategory.services.map((service, index) => (
                    <Card
                        key={index}
                        title={service.title}
                        className="card"
                        style={{ width: "250px", margin: "10px" }}
                    >
                        <div style={{ fontSize: "40px", textAlign: "center", marginBottom: "10px" }}>
                            <img src={service.icon} alt={service.title} style={{ width: "40px", height: "40px" }} />
                        </div>
                        <p>{service.description}</p>
                    </Card>
                ))}
            </div>
            <Button
                label="Contattaci"
                className={`contact-button`}
                style={{ marginTop: "20px" }}
                onClick={openContactForm}
            />

            <Dialog
                visible={displayContact}
                style={{ width: "450px" }}
                className="container_dialog"
                modal
                onHide={closeContactForm}
            >
                <FormInfo />
            </Dialog>

        </div>
    );
};

export default Presentation;
