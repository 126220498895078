import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style/Usefulness.css';


import Img1 from "../../asset/Img1.png";
import Img2 from "../../asset/Img2.png";
import Img3 from "../../asset/Img3.png";
import Img4 from "../../asset/Img4.png";



const Usefulness = () => {


    return (
        <div className="background-gray">
            <div className="container_grid">
                <div className="first_item"
                    style={{
                        backgroundImage: `url(${Img1})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>Sempre al tuo fianco</h3>
                    <p className='descrip_carduse'>Ci impegniamo affinché ogni dispositivo e servizio sia all'altezza delle tue aspettative.
                        Siamo il partner su cui potrai sempre fare affidamento.</p>
                </div>


                <div className="second_item" style={{
                    backgroundImage: `url(${Img2})`, // Usa il logo come sfondo
                    backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                    backgroundPosition: 'center', // Centra l'immagine
                    backgroundRepeat: 'no-repeat', // Evita ripetizioni
                }}>
                    <h3 className='title_carduse'>Soluzioni orientate al cliente</h3>
                    <p className='descrip_carduse'>Siamo qui per rispondere a soluzioni pensate appositamente per te. Ci adattiamo a ogni contesto
                        per rendere la tecnologia sempre accessibile.</p>
                </div>


                <div className="third_item"
                    style={{
                        backgroundImage: `url(${Img3})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>Qualità e innovazioni continua</h3>
                    <p className='descrip_carduse'>Selezioniamo e proponiamo sempre tecnologia di alta qualità, aggiornata ed affidabile.
                        Con noi puoi trovare soluzioni progettate per offrire il massimo in sicurezza e prestazioni.</p>
                </div>


                <div className="four_item"
                    style={{
                        backgroundImage: `url(${Img4})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>Supporto tecnico</h3>
                    <p className='descrip_carduse'>La tecnologia non deve mai essere un ostacolo: il nostro team è qui per ofrrirti un supporto rapido e mirato.
                        Affidati a noi per una gestione della tecnologia semplice e senza pensieri. </p>
                </div>
            </div>

        </div>
    );
};

export default Usefulness;
