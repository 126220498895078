import React from 'react';
import '../../style/Values.css'; 

const Values = () => {
  const values = [
    { title: 'Chiarezza operativa', description: 'Comunicazione trasparente e diretta per ogni servizio' },
    { title: 'Soluzioni agili', description: 'Risposte rapide e soluzioni flessibili, pensate per adattarsi alle tue esigenze.' },
    { title: 'Supporto efficiente', description: 'Per risolvere ogni problema con rapidità e competenza.' },
    { title: 'Innovazione', description: 'Tecnologia sempre aggiornata, per offrirti il massimo della qualità e delle prestazioni.' },
  ];

  return (
    <div className="values-section">
      <h2 className='title_about'>I nostri Valori</h2>
      <div className="values-container">
        {values.map((value, index) => (
          <div key={index} className="value-card">
            <h3>{value.title}</h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Values;
