import React from "react";
import "../components/PrivacyPolicy/style/PrivacyPolicy.css";

const PrivacyPolicyPage = () => {
  return (
    <div className="data-mastery-container">
      <article>
        <header>
          <h1>Privacy Policy</h1>
          <p>Ultimo aggiornamento: 9 dicembre 2024</p>
        </header>

        <section>
          <p>
            Grazie per aver visitato l'Informativa sulla Privacy di Se.Me.Te
            SRL. Questa informativa spiega come Se.Me.Te SRL (di seguito
            "Se.Me.Te SRL", "noi", "ci" o "nostro/a") raccoglie, utilizza e
            condivide informazioni personali che ti riguardano (di seguito
            "utente") quando visiti www.semete.it (di seguito “sito”,
            “pagina/e”).
          </p>
          <p>
            L’informativa vale solo per il sito www.semete.it e non per altri
            che potresti visitare attraverso i link presenti sulle pagine.
          </p>
          <p>
            Si precisa che il trattamento dei dati personale avviene unicamente
            previo rilascio del consenso da parte dell’utente unicamente per le
            finalità specificate in questo documento.
          </p>
        </section>

        <section>
          <h2>1. Titolare del Trattamento</h2>
          <p>Il Titolare del trattamento dei dati è: Se.Me.Te SRL</p>
          <p>Via Benedetto Croce, 8</p>
          <p>95039 Trecastagni (CT)</p>
          <p>
            Email: <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a>
          </p>
        </section>

        <section>
          <h2>2. Tipologie di Dati Raccolti</h2>
          <p>
            Navigando sul nostro sito l’utente potrebbe lasciare volontariamente
            i dati personali attraverso il modulo di ricontatto; la relativa
            privacy policy è visionabile attraverso il link presente sul modulo
            e <a href="/privacy-policy-form-ricontatto">qui.</a>
          </p>
        </section>

        <section>
          <h2>3. Dati forniti dall’utente</h2>
          <p>
            L’invio di mail da parte dell’utente all’indirizzo indicato su
            questo sito comporta la successiva acquisizione dell’indirizzo del
            mittente e degli eventuali dati presenti nel messaggio; essi saranno
            necessari per rispondere alla richiesta.
          </p>
        </section>

        <section>
          <h2>4. Cookie</h2>
          <p>
            Il nostro sito web non utilizza cookie per raccogliere informazioni
            sugli utenti né per altre finalità. Non vengono utilizzati cookie
            tecnici, analitici o di profilazione.
          </p>
        </section>

        <section>
          <h2>5. Finalità del trattamento</h2>
          <p>
            I dati personali raccolti dal nostro sito sono esclusivamente
            finalizzati per rispondere alle richieste attraverso il modulo di
            contatto e a fini di analisi statistica.
          </p>
        </section>

        <section>
          <h2>6. Destinatari dei dati personali</h2>
          <p>
            I dati raccolti da noi tramite il modulo di ricontatto saranno
            trattati esclusivamente da personale interno di Se.Me.Te SRL, ad
            eccezione che la condivisione degli stessi sia richiesta dalla
            legge.
          </p>
        </section>

        <section>
          <h2>7. Periodo di Conservazione dei Dati</h2>
          <p>
            Conserviamo i tuoi dati solo per il tempo strettamente necessario a
            soddisfare le finalità per cui sono stati raccolti.
          </p>
        </section>

        <section>
          <h2>8. Diritto di accesso</h2>
          <p>
            L’utente ha il diritto di ottenere dal titolare del trattamento la
            conferma che sia o meno in corso un trattamento di dati personali
            che lo riguardano e in tal caso, di ottenere l’accesso ai dati
            personali e alle seguenti informazioni:
          </p>
          <ul>
            <li>Le finalità del trattamento;</li>
            <li>Le categorie di dati personali in questione;</li>
            <li>
              I destinatari o le categorie di destinatari a cui i dati personali
              sono stati o saranno comunicati;
            </li>
            <li>I diritti esplicati in questo documento</li>
          </ul>
        </section>

        <section>
          <h2>9. Trasferimenti di Dati all'Estero</h2>
          <p>
            I dati inseriti attraverso il modulo di ricontatto non saranno
            inviati a paesi terzi o organizzazioni internazionali.
          </p>
        </section>

        <section>
          <h2>10. Diritto alla cancellazione e rettifica dei dati</h2>
          <p>
            L’utente ha il diritto di richiedere in qualsiasi momento inviando
            una mail a{" "}
            <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a> di
            cancellare o rettificare i propri dati.
          </p>
        </section>

        <section>
          <h2>11. Modifiche alla Privacy Policy</h2>
          <p>
            Potremmo aggiornare questa informativa periodicamente. Ti invitiamo
            a consultare questa pagina regolarmente.
          </p>
        </section>

        <section>
          <h2>12. Limitazione del trattamento</h2>
          <p>
            L’utente ha il diritto di ottenere dal titolare del trattamento la
            limitazione del trattamento quando ricorre una delle seguenti
            ipotesi:
          </p>
          <ul>
            <li>
              L’utente contesta l’esattezza dei dati personali, per il periodo
              necessario al titolare del trattamento per verificare l’esattezza
              di tali dati personali;
            </li>
            <li>
              Il trattamento è illecito e l’utente si oppone alla cancellazione
              dei dati personali e chiede invece che ne sia limitato l’utilizzo;
            </li>
            <li>
              Benché il titolare del trattamento non ne abbia più bisogno ai
              fini del trattamento, i dati personali sono necessari all’utente
              per l’accertamento, l’esercizio o la difesa di un diritto in sede
              giudiziaria;
            </li>
            <li>
              L’utente si è opposto al trattamento ai sensi dell’articolo 21,
              paragrafo 1, in attesa della verifica in merito all’eventuale
              prevalenza dei motivi legittimi del titolare del trattamento
              rispetto a quelli dell’interessato.
            </li>
          </ul>
          <p>
            Qualora l’utente volesse esercitare il diritto di limitazione dei
            dati, potrà inviare una mail a{" "}
            <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a>
          </p>
        </section>

        <section>
          <h2>13. Diritto di reclamo</h2>
          <p>
            L’utente ha diritto di proporre reclamo presso l’autorità di
            controllo nello stato membro cui risiede abitualmente, lavora,
            oppure nel luogo ove si è verificata la presunta violazione.
          </p>
        </section>
      </article>
    </div>
  );
};

export default PrivacyPolicyPage;
